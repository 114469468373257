import React, { useContext, useState, useEffect, useRef } from "react";
import { Form, FormControl, Button, InputGroup, Alert } from "react-bootstrap";
import farmersPlaceholder from "../../Assets/farmercultivating.png";
import { useParams, useNavigate } from "react-router-dom";
import FarmersBlogPage from "../FarmersBlogs/FarmersBlogPage";
import "./farmersBlogEditor.css";
import JoditEditor from "jodit-react";
import { AuthContext } from "../../App";
import axios from "axios";
import config from "../../Boot/config.json";
import { FaArrowLeftLong } from "react-icons/fa6";
import { TiDelete } from "react-icons/ti";
import AlertDismissible from "../../Components/Utils/Alert";

function FarmersBlogEditor({
  editBlog,
  editImage,
  editCategory,
  editDescription,
  editPreview,
  editStatus,
  editMetaDescription,
  editSeoTitle,
  editSeoSlug,
}) {
  const { id } = useParams();
  const seoBlogId = id;
  const navigation = useNavigate();
  const [blog, setBlogs] = useState("");
  const [image, setImage] = useState(null);
  const [cardPreview, setCardPreview] = useState("");
  const [description, setDescription] = useState("");
  const [showBlog, setShowBlog] = useState(false);
  const { tokenData, pageNum } = useContext(AuthContext);
  const [editImgStatus, setImgEditStatus] = useState(editStatus);
  const [fileError, setFileError] = useState("");
  const [showDetails, setShowDetails] = useState(true);
  const editor = useRef(null);
  const [editCategoryStatus, setEditCategoryStatus] = useState(false);
  const [dropdownOpt, setDropdownOpt] = useState([]);
  const [category, setCategory] = useState("");
  const [Categoryfield, setCategoryfield] = useState();
  const [activePopup, setActivePopup] = useState(false);
  const [reason, setReason] = useState("");
  const [error, setError] = useState("");
  const [alerts, setAlerts] = useState({
    alertMsg: "",
    alertValue: false,
    alertStatus: false,
  });

  const [seoDetails, setSeoDetails] = useState({
    seoTitle: "",
    metaDescription: "",
    seoSlug: "",
    seoActive: false,
  });

  const axiosInstance = axios.create({
    baseURL: `${config.baseUrl}/Blogs`,
    headers: {
      Authorization: tokenData.token,
    },
  });

  useEffect(() => {
    setBlogs(editBlog);
    setImage(editImage);
    setCategory(editCategory);
    setDescription(editDescription);
    setCardPreview(editPreview);
    setSeoDetails((prevData) => ({
      ...prevData,
      seoTitle: editSeoTitle,
      metaDescription: editMetaDescription,
      seoSlug: editSeoSlug,
    }));
  }, [
    editBlog,
    editImage,
    editCategory,
    editDescription,
    editPreview,
    editSeoSlug,
  ]);

  useEffect(() => {
    try {
      axiosInstance
        .get("/categoryDropdown")
        .then((response) => {
          setDropdownOpt(response.data.data);
        })
        .catch(() => {});
    } catch (err) {
      console.log(err);
    }
  }, [dropdownOpt]);

  function alertMsg(messages, alertStatus) {
    setAlerts((prevState) => ({
      ...prevState,
      alertMsg: messages,
      alertValue: true,
      alertStatus,
    }));
  }

  const handleReason = (e) => {
    const { id, value } = e.target;
    if (id === "reason") {
      setReason(value);
    }
  };

  const imageEditHandler = () => {
    setImgEditStatus(!editImgStatus);
  };
  const handleShowDetails = () => {
    setShowDetails(!showDetails);
  };
  const handleCheckboxChange = () => {
    setEditCategoryStatus(!editCategoryStatus);
  };

  const handleChange = (e) => {
    const { id, value, files } = e.target;
    if (id === "blog") {
      setBlogs(value);
    }
    if (id === "imageFile" && files.length > 0) {
      const selectedFile = files[0];
      const allowedExtensions = ["jpg", "jpeg", "png"];
      const fileExtension = selectedFile.name.split(".").pop().toLowerCase();

      if (!allowedExtensions.includes(fileExtension)) {
        setFileError("Please select a valid JPG or PNG file.");
      } else {
        setFileError(""); // Clear any previous file error
        setImage(selectedFile);
      }
    }
    if (id === "category") {
      setCategory(value);
    }
    if (id === "cardpreview") {
      setCardPreview(value);
    }
    if (id === "handleCategory") {
      setCategoryfield(value);
    }
  };

  //seo start

  const handleSeoChange = (e) => {
    const { value, id } = e.target;
    if (id === "seoTitle") {
      setSeoDetails((prevState) => ({
        ...prevState,
        seoTitle: value,
      }));
    } else if (id === "metaDescription") {
      setSeoDetails((prevState) => ({
        ...prevState,
        metaDescription: value,
      }));
    } else if (id === "seoSlug") {
      setSeoDetails((prevState) => ({
        ...prevState,
        seoSlug: value,
      }));
    }
  };

  const SEO = () => {
    return (
      <button
        className=" text-white mt-3 p-2 rounded-2 mx-3 statUpdateBtn"
        onClick={() =>
          setSeoDetails((prevState) => ({ ...prevState, seoActive: true }))
        }
      >
        SEO
      </button>
    );
  };

  const seoDetailsForm = () => {
    return (
      <div className="seoFormWrapper position-fixed w-100 d-flex justify-content-center align-items-center top-0 end-0">
        <form action="" className="bg-white p-3 shadow-sm seoform rounded-2">
          <div className="m-2">
            <label htmlFor="title">Slug</label>
            <input
              type="text"
              name="seoSlug"
              id="seoSlug"
              placeholder="Slug"
              value={seoDetails.seoSlug}
              className="rounded-1 p-1"
              onChange={(e) => handleSeoChange(e)}
            />
          </div>

          <div className="m-2">
            <div className="d-flex justify-content-between">
              <label htmlFor="title">Title</label>
              <div>
                {seoDetails && seoDetails.seoTitle
                  ? seoDetails.seoTitle.length
                  : 0}
                /60
              </div>
            </div>
            <input
              type="text"
              name="seoTitle"
              id="seoTitle"
              placeholder="title"
              maxLength={60}
              value={seoDetails.seoTitle}
              className="rounded-1 p-1"
              onChange={(e) => handleSeoChange(e)}
            />
          </div>
          <div className="m-2">
            <div className="mt-3 d-flex justify-content-between">
              <label htmlFor="meta description">Meta Description</label>
              <div>
                {seoDetails && seoDetails.metaDescription
                  ? seoDetails.metaDescription.length
                  : 0}
                /150
              </div>
            </div>
            <textarea
              name="metaDescription"
              id="metaDescription"
              cols="30"
              rows="5"
              placeholder="Meta Description"
              maxLength={150}
              className="rounded-1 p-1"
              value={seoDetails.metaDescription}
              onChange={(e) => handleSeoChange(e)}
            ></textarea>
          </div>

          <div className="d-flex">
            <button
              className="p-2 border-0 text-white shadow-sm statBtn m-2 statApproveBtn"
              onClick={(e) => hadleSeoSubmit(e, seoBlogId)}
            >
              Submit
            </button>
            <button
              className="p-2 border-0 shadow-sm statBtn m-2 bg-secondary text-white"
              onClick={() =>
                setSeoDetails((prevState) => ({
                  ...prevState,
                  seoActive: false,
                }))
              }
            >
              close
            </button>
          </div>
        </form>
      </div>
    );
  };

  const hadleSeoSubmit = (e, seoBlogId) => {
    e.preventDefault();

    try {
      const formData = {
        seoTitle: seoDetails.seoTitle,
        metaDescription: seoDetails.metaDescription,
        seoSlug: seoDetails.seoSlug,
      };
      axiosInstance
        .patch(`/blogSeo/${seoBlogId}`, formData)
        .then((response) => {
          alertMsg("SEO updated sucessfully", true);
          setTimeout(() => {
            setAlerts((prevState) => ({ ...prevState, alertValue: false }));
          }, 2000);
          setSeoDetails({
            seoTitle: response.data.data.seoTitle,
            metaDescription: response.data.data.metaDescription,
            seoSlug: response.data.data.seoSlug,
            seoActive: false,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };

  //seo ending

  const handleContentChange = (newContent) => {
    setDescription(newContent);
  };

  const buttonSaveDraftHandle = (id, tokenData) => {
    if (
      tokenData.token &&
      (tokenData.roleId === "Guest" || tokenData.roleId === "User")
    ) {
      return (
        <button
          className="statBtn text-white mt-3 p-2  mx-3 statDraftBtn"
          onClick={(e) => handleDraftInputChange(e, "Guest")}
        >
          Save Draft
        </button>
      );
    } else if (tokenData.token && tokenData.roleId === "Admin" && id) {
      return (
        <button
          className="statBtn text-white mt-3 p-2  mx-3 statReviewBtn"
          onClick={(e) => setActivePopup(true)}
        >
          Review
        </button>
      );
    }
  };

  const buttonHandle = (id, tokenData) => {
    if (
      tokenData.token &&
      (tokenData.roleId === "Guest" || tokenData.roleId === "User")
    ) {
      return id ? (
        <button
          className="statBtn text-white mt-3 p-2  statApproveBtn"
          onClick={(e) => handleEditPost(e, "", "GuestToPending")}
        >
          Send to admin
        </button>
      ) : (
        <button
          className="statBtn text-white p-2  statApproveBtn"
          onClick={(e) => handleSubmit(e, "AdminToMyBlogs")}
        >
          Send to admin
        </button>
      );
    } else if (tokenData.token && tokenData.roleId === "Admin") {
      return id ? (
        <button
          className="statBtn text-white p-2  statApproveBtn "
          onClick={(e) => handleEditPost(e, "", "AdminToBlogs")}
        >
          Approve
        </button>
      ) : (
        <button
          className="statBtn text-white p-2  statApproveBtn"
          onClick={(e) => handleSubmit(e, "AdminToBlogs")}
        >
          Approve
        </button>
      );
    }
  };

  const HandleClick = () => {
    setShowBlog(() => true);
  };

  const navigate = useNavigate();
  const HandleaddClick = () => {
    navigate("/blogs");
  };

  if (showBlog) {
    return <FarmersBlogPage />;
  }

  const backButton = (id) => {
    return id ? (
      <button
        className="backToBlog py-1 px-2 position-fixed z-1"
        onClick={HandleClick}
      >
        <FaArrowLeftLong />
      </button>
    ) : (
      <button
        className="backButton  py-1 px-2 position-fixed"
        onClick={HandleaddClick}
      >
        <FaArrowLeftLong />
      </button>
    );
  };

  const reasonPopup = () => {
    return (
      <>
        <div
          className={`bg-secondary rounded-2 px-3 position-fixed  ${
            activePopup ? "shownReason" : "hideReason"
          }  `}
        >
          <h1 className="text-white">Reason</h1>
          <textarea
            name="reason"
            id="reason"
            className="rejectReason"
            cols="30"
            rows="5"
            onChange={(e) => handleReason(e)}
          ></textarea>
          <button
            className="border-0 my-3 rounded-1 p-1"
            onClick={(e) => handleDraftInputChange(e, "Admin")}
          >
            {error === "please mention reason" && (
              <p className="text-danger p-1">{error}</p>
            )}
            Review
          </button>
          <button
            className="p-1 border-0 my-3 rounded-1 ms-3"
            onClick={() => setActivePopup(false)}
          >
            Close
          </button>
        </div>
      </>
    );
  };

  //delete category
  const deleteCategoryHandler = (id) => {
    try {
      axiosInstance
        .delete(`/deleteCategory/${id}`)
        .then((response) => {})
        .catch((err) => {});
    } catch (err) {
      console.log(err);
    }
  };

  //adding category
  const AddCategoryHandler = () => {
    try {
      const formData = { category: Categoryfield };
      axiosInstance
        .post("/AddCategory", formData)
        .then((response) => {
          alertMsg("Category added sucessfully", true);
          setTimeout(() => {
            setAlerts((prevState) => ({ ...prevState, alertValue: false }));
          }, 1500);
          setCategoryfield("");
          setCategory(Categoryfield);
          console.log(Categoryfield);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };

  //edit a post
  const handleEditPost = (e, changedStatus = "", buttonStatus) => {
    e.preventDefault();

    const myFormData = new FormData();
    myFormData.append("blog", blog);
    myFormData.append("category", category);
    myFormData.append("content", description);
    myFormData.append("preview", cardPreview);
    myFormData.append("image", image);
    myFormData.append("publish", true);
    if (changedStatus === "pending") {
      myFormData.append("changedStatus", changedStatus);
    }

    try {
      axiosInstance
        .patch(`/editBlogDetails/${id}`, myFormData)
        .then((response) => {
          // alert(`Blog Updated Sucessfully`);
          if (
            buttonStatus === "Pending" ||
            buttonStatus === "GuestToPending" ||
            buttonStatus === "AdminToPending"
          ) {
            alertMsg("Blog moved to pending", true);
            setTimeout(() => {
              setAlerts((prevState) => ({ ...prevState, alertValue: false }));
              sessionStorage.setItem(
                "statusBar",
                JSON.stringify({
                  publish: false,
                  adminApproval: "pending",
                  page: 0,
                })
              );
              navigate("/protected/myBlogs");
            }, 1500);
          } else if (buttonStatus === "AdminToBlogs") {
            alertMsg("Blog published successfully", true);
            setTimeout(() => {
              setAlerts((prevState) => ({ ...prevState, alertValue: false }));
              navigate("/blogs");
            }, 1500);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.log(error);
    }
  };
  const handlePost = (e, isDraft, buttonStatus) => {
    e.preventDefault();
    console.log("description-", description);

    if (!blog) {
      return setError("please enter blog name");
    } else if (!category) {
      return setError("please select category");
    } else if (!cardPreview) {
      return setError("please enter card preview");
    } else if (!description) {
      return setError("please enter content below");
    }
    // if (!image) {
    //   setImage(farmersPlaceholder);
    // }
    console.log("image==========", image);
    const myFormData = new FormData();
    myFormData.append("blog", blog);
    myFormData.append("category", category);
    myFormData.append("content", description);
    myFormData.append("preview", cardPreview);
    myFormData.append("image", image);
    myFormData.append("publish", isDraft);
    myFormData.append("id", id);
    myFormData.append("reason", reason);
    try {
      axiosInstance
        .post("BlogsDetails", myFormData)
        .then((response) => {
          if (buttonStatus === "Admin") {
            alertMsg("Blog rejected", true);
            setActivePopup(false);
            setTimeout(() => {
              setAlerts((prevState) => ({ ...prevState, alertValue: false }));
              sessionStorage.setItem(
                "statusBar",
                JSON.stringify({
                  publish: false,
                  adminApproval: "rejected",
                  page: 0,
                })
              );
              navigate("/protected/myBlogs");
            }, 1500);
          } else if (buttonStatus === "Guest") {
            alertMsg("Blog saved as draft", true);
            setTimeout(() => {
              setAlerts((prevState) => ({ ...prevState, alertValue: false }));
              sessionStorage.setItem(
                "statusBar",
                JSON.stringify({
                  publish: false,
                  adminApproval: "working",
                  page: 0,
                })
              );
              navigate("/protected/myBlogs");
            }, 1500);
          } else if (buttonStatus === "AdminToBlogs") {
            alertMsg("Blog published successfully", true);
            setTimeout(() => {
              setAlerts((prevState) => ({ ...prevState, alertValue: false }));
              navigate("/blogs");
            }, 1500);
          } else if (buttonStatus === "AdminToMyBlogs") {
            alertMsg("Blog moved to pending", true);
            setTimeout(() => {
              setAlerts((prevState) => ({ ...prevState, alertValue: false }));
              sessionStorage.setItem(
                "statusBar",
                JSON.stringify({
                  publish: false,
                  adminApproval: "pending",
                  page: 0,
                })
              );
              navigate("/protected/myBlogs");
            }, 1500);
          }
          // if (isDraft === false) {
          //   navigation("/protected/drafts");
          // } else if (isDraft === true) {
          //   navigation("/blogs");
          // }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.log(error);
    }
  };

  // Usage
  const handleChangeStatus = (e, buttonStatus) =>
    handleEditPost(e, "pending", buttonStatus);
  const handleDraftInputChange = (e, buttonStatus) =>
    handlePost(e, false, buttonStatus);
  const handleSubmit = (e, buttonStatus) => {
    if (!fileError) {
      handlePost(e, true, buttonStatus);
    }
  };

  return (
    <div className="d-flex flex-column justify-content-center align-items-center editorWrapper">
      <div
        className={`position-absolute  ${
          alerts.alertValue ? "animateAlert" : "hideAlert"
        }`}
      >
        {alerts.alertValue && (
          <AlertDismissible
            message={alerts.alertMsg}
            value={alerts.alertStatus}
          />
        )}
      </div>
      {activePopup && reasonPopup()}

      <div className="postEditorPage d-flex justify-content-center">
        <div>{backButton(id)}</div>
        <div className="editorBtns">
          {buttonSaveDraftHandle(id, tokenData)}
          {buttonHandle(id, tokenData)}

          {tokenData.token && tokenData.roleId === "Admin" && (
            <>
              {id ? (
                <button
                  className="statBtn text-white mt-3 p-2  statPendingBtn mx-3"
                  onClick={(e) => handleChangeStatus(e, "AdminToPending")}
                >
                  Pending
                </button>
              ) : (
                <button
                  className="statBtn text-white mt-3 p-2  mx-3 statDraftBtn"
                  onClick={(e) => handleDraftInputChange(e, "Guest")}
                >
                  Save Draft
                </button>
              )}
            </>
          )}
          {seoBlogId !== undefined &&
            seoBlogId !== null &&
            seoBlogId !== "" &&
            SEO()}
        </div>
        {seoDetails.seoActive && seoDetailsForm()}
        <div className="postEditor">
          <Form onSubmit={handleSubmit}>
            {showDetails && (
              <div>
                <Form.Label className="m-0 p-0">Blog Title</Form.Label>
                <FormControl
                  type="text"
                  id="blog"
                  name="blog"
                  value={blog}
                  onChange={handleChange}
                  placeholder="blog"
                  className="mb-3"
                />
                {error === "please enter blog name" && (
                  <p className="text-danger">{error}</p>
                )}
                <Form.Label className="m-0 p-0">Image</Form.Label>
                <InputGroup>
                  {editImgStatus ? (
                    <FormControl
                      type="text"
                      name="image"
                      value={typeof editImage === "string" ? editImage : ""}
                      onChange={handleChange}
                      readOnly={typeof editImage === "string"}
                      className="mb-3"
                    />
                  ) : (
                    <FormControl
                      type="file"
                      name="image"
                      accept="image/jpeg, image/png"
                      id="imageFile"
                      onChange={handleChange}
                      className="mb-3"
                    />
                  )}
                  <Button
                    variant="outline-secondary"
                    onClick={imageEditHandler}
                    id="button-addon2"
                    // className="mt-2"
                  >
                    Edit
                  </Button>
                </InputGroup>
                {/* {error === "please upload image" && (
                  <p className="text-danger">{error}</p>
                )} */}

                <Form.Label>Categories</Form.Label>
                <Form.Select
                  onChange={handleChange}
                  id="category"
                  name="category"
                  value={category}
                >
                  <option>Choose a Category</option>
                  {dropdownOpt.map((option) => (
                    <option keyId={option._id} value={option.category}>
                      {option.category}
                    </option>
                  ))}
                </Form.Select>
                {error === "please select category" && (
                  <p className="text-danger">{error}</p>
                )}
                {fileError ? <p className="text-danger">{fileError} </p> : ""}
                <InputGroup className="mt-3">
                  {editCategoryStatus && (
                    <>
                      <FormControl
                        type="text"
                        id="handleCategory"
                        name="handleCategory"
                        value={Categoryfield}
                        onChange={handleChange}
                        placeholder="Category"
                      />
                      <Button
                        variant="outline-secondary"
                        onClick={AddCategoryHandler}
                        id="button-addon2"
                        // className="mt-2 "
                      >
                        Add
                      </Button>
                    </>
                  )}
                </InputGroup>
                <Form.Group className="mb-3 mt-1">
                  <Form.Check
                    type="checkbox"
                    label="Edit Category"
                    onChange={() => handleCheckboxChange(id)}
                    id="additionalInfoCheckbox"
                  />
                </Form.Group>
                <InputGroup>
                  {editCategoryStatus &&
                    (dropdownOpt.length !== 0 ? (
                      <div className="bg-white my-2 w-100 rounded-3">
                        <ul type="none" className="d-flex flex-wrap">
                          {dropdownOpt.map((category) => (
                            <li
                              key={category._id}
                              title={category.category}
                              className="bg-dark rounded-3 text-white m-2 py-2 px-1 d-flex justify-content-between wrapDeleteBtns overflow-hidden"
                            >
                              <div
                                className="w-75 d-flex justify-content-center align-items-center overflow-hidden"
                                style={{ wordBreak: "break-all" }}
                              >
                                <span className="w-75">
                                  {category.category}
                                </span>
                              </div>
                              {tokenData.token &&
                                tokenData.roleId === "Admin" && (
                                  <TiDelete
                                    size="1.7em"
                                    className="deleteCategory "
                                    onClick={() => {
                                      deleteCategoryHandler(category._id);
                                    }}
                                  />
                                )}
                            </li>
                          ))}
                        </ul>
                      </div>
                    ) : (
                      <></>
                    ))}
                </InputGroup>
                <Form.Label className="m-0 p-0">Card Preview</Form.Label>
                <FormControl
                  type="text"
                  id="cardpreview"
                  name="cardPreview"
                  value={cardPreview}
                  onChange={handleChange}
                  placeholder='eg:"The Indian high commission in Ottawa on Friday (local time) issued "'
                />
                {error === "please enter card preview" && (
                  <p className="text-danger">{error}</p>
                )}
              </div>
            )}
            <Form.Check
              type="checkbox"
              label="Hide Details"
              className=" mt-3 mb-2"
              onChange={handleShowDetails}
              id="showDetails"
            />
            {error === "please enter content below" && (
              <p className="text-danger">{error}</p>
            )}
            <JoditEditor
              ref={editor}
              config={config}
              id="content"
              name="content"
              className="description"
              value={description}
              onChange={handleContentChange}
              tabIndex={1}
            />
          </Form>
        </div>
      </div>
    </div>
  );
}

export default FarmersBlogEditor;
