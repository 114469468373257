import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "./farmingSoilCropManagement.css";
import farmingSoilCropJson from "../farmingSoilCropJson.json";
import NattuluIcon from "../../../Assets/NattuluIcon.jpg";
import advantages from "../../../Assets/Advantages.png";
import disadvantages from "../../../Assets/disadvantages.png";
import leaves from "../../../Assets/leavesNew.png";

function DynamicData() {
  const url = useLocation(window.location);
  const decodedPathname = decodeURIComponent(url.pathname.split("/")[2]);
  const data = farmingSoilCropJson[decodedPathname.replace(" ", "")];
  return { decodedPathname, data };
}
function FarmingSoilCropManagement() {
  return (
    <div>
      <WhatWeAnswer />
      <QuestionOne />
      <HowToImplementQ2 />
      <AdvantagesDisadvantages />
      <Myths />
    </div>
  );
}

export default FarmingSoilCropManagement;

function WhatWeAnswer() {
  const { data, decodedPathname } = DynamicData();
  return (
    <div className="d-flex justify-content-center align-items-center  questionsPhrase">
      <div className="d-flex flex-column justify-content-center align-items-center">
        <div className="p-4 knowledgeOnGoodPractice">
          <h1>
            From Knowledge to Cultivation: Digging Deep into Organic Farming for
            Sustainable Farming Success!
          </h1>
        </div>
        <div className="d-flex flex-column flex-sm-row questions px-2 mx-2 ">
          <div className="px-0 m-0 m-sm-2 px-sm-2">
            <div className="p-1 m-2 question d-flex  align-items-center">
              <div>
                <img
                  src={NattuluIcon}
                  alt="grass"
                  className="questionImg mx-2"
                  loading="lazy"
                />
              </div>
              <div>What is {decodedPathname} ?</div>
            </div>
            <div className="p-1 m-2 question d-flex  align-items-center">
              <div>
                <img
                  src={NattuluIcon}
                  alt="grass"
                  className="questionImg mx-2"
                  loading="lazy"
                />
              </div>
              <div>How to implement in Farming ?</div>
            </div>
            <div className="p-1 m-2 question d-flex  align-items-center">
              <div>
                <img
                  src={NattuluIcon}
                  alt="rice"
                  className="questionImg mx-2"
                  loading="lazy"
                />
              </div>
              <div>What are the benefits of {decodedPathname} ?</div>
            </div>
          </div>
          <div className="px-0 m-0 m-sm-2 px-sm-2">
            <div className="p-1 m-2 question d-flex  align-items-center">
              <div>
                <img
                  src={NattuluIcon}
                  alt="rice"
                  className="questionImg mx-2"
                  loading="lazy"
                />
              </div>
              <div>What are the disadvantages of {decodedPathname} ?</div>
            </div>
            <div className="p-1 m-2 question d-flex  align-items-center">
              <div>
                <img
                  src={NattuluIcon}
                  alt="rice"
                  className="questionImg mx-2"
                  loading="lazy"
                />
              </div>
              <div> Misconceptions or Myths on {decodedPathname} ?</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function QuestionOne() {
  const { data, decodedPathname } = DynamicData();
  return (
    <div>
      <div className="d-flex flex-column justify-content-center align-items-center p-sm-3 m-md-3 m-2 p-2">
        <h2 className="q1Heading">What is {decodedPathname} ? </h2>
        <div className="q1Description p-2 p-sm-3">{data.description}</div>
      </div>
    </div>
  );
}

function HowToImplementQ2() {
  const { data, decodedPathname } = DynamicData();
  const [imageSrc, setImageSrc] = useState(null);

  useEffect(() => {
    // Dynamically import the image based on data.imageUrl
    import(`../../../Assets/${data.imageUrl}`)
      .then((module) => {
        // Once the image is loaded, update the state with the image source
        setImageSrc(module.default);
      })
      .catch((error) => {
        console.error("Error loading image:", error);
      });
  }, [data.imageUrl]);
  return (
    <div>
      <div>
        <div className="HowToImplementHeading">
          <h2>How to implement in Farming ?</h2>
        </div>
        <div className="d-flex justify-content-center HowToImpWrapper">
          <div className="howToImpWrapp d-flex justify-content-center">
            <img
              src={imageSrc}
              alt="wheat"
              className="howToImplementImg"
              loading="lazy"
            />
          </div>
          <div className="howToImpDescWrapp d-flex flex-column justify-content-center">
            {data.howToImplement.map((eachPoint) => (
              <div>
                <p>{eachPoint}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

function AdvantagesDisadvantages() {
  const { data, decodedPathname } = DynamicData();
  return (
    <div className="advantagesDisavantagesWrapper">
      <div className="d-flex justify-content-center my-5 py-5 advantagesDisavantages">
        <div className="advantages bg-white p-4 m-4 position-relative ">
          <img
            src={advantages}
            className="position-absolute advDisImg"
            alt="farming pros "
            loading="lazy"
          />
          <h2>Advantages</h2>
          <div className="underline"></div>
          <div className="py-3 advPoints">
            {data.advantages.map((eachPoint) => (
              <div>
                <p>{eachPoint}</p>
              </div>
            ))}
          </div>
        </div>
        <div className="disadvantages bg-white p-4 m-4 position-relative ">
          <img
            src={disadvantages}
            alt="farming cons"
            className="position-absolute advDisImg"
            loading="lazy"
          />
          <h2>Disadvantages</h2>
          <div className="underline"></div>
          <div className=" py-3">
            {data.disadvantages.map((eachPoint) => (
              <div>
                <p>{eachPoint}</p>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div></div>
    </div>
  );
}

function Myths() {
  const { data, decodedPathname } = DynamicData();
  return (
    <div className="d-flex justify-content-center">
      <div className="myths position-relative px-5 py-4 my-4 d-flex flex-column justify-content-center align-items-start ">
        <img
          src={leaves}
          alt="leaves"
          className="position-absolute mythsLeavesImg"
          loading="lazy"
        />
        <div className="py-4">
          <h2>Misconceptions or Myths:</h2>
          <div className="mythPoints">
            {data.myths.map((eachPoint) => (
              <div>
                <p>{eachPoint}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
