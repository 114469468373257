import React from "react";
import cropRotation from "../../Assets/cropRotation.jpg";
import companionPlanting from "../../Assets/companion.jpeg";
import pestControl from "../../Assets/pestControl.webp";
import weedManagement from "../../Assets/weedManagement.png";
import leaves from "../../Assets/leaves.png";
import "./cropManagement.css";
import { useNavigate } from "react-router-dom";

function CropManagement() {
  const navigation = useNavigate();
  function urlEncoded(title) {
    return title
      .toLowerCase() // Optional: convert to lowercase
      .replace(/[^a-z0-9]+/g, "-") // Replace non-alphanumeric characters with hyphens
      .replace(/^-+|-+$/g, ""); // Remove leading and trailing hyphens
  }
  function detailsPage(category) {
    navigation(`/CropManagement/${urlEncoded(category)}`);
  }
  return (
    <div className="d-flex justify-content-center flex-column justify-content-center ">
      <div className="position-relative overflow-hidden  ">
        <div className="transperentCircleLayerRight rounded-circle position-absolute"></div>
        <img
          src={leaves}
          alt="crop management"
          className="position-absolute leaves"
          loading="lazy"
        />
        <div className="cropManageBreif  w-100 d-flex justify-content-center  my-2 my-sm-5  ">
          <div className="w-75">
            <h1>Crop Management</h1>
            <p className="cropMangementPara">
              Crop management is the backbone of successful farming, involving
              precise planning and execution. It integrates strategies like crop
              rotation, companion planting, and effective pest and weed control.
              By prioritizing soil health and ecological balance, crop
              management ensures sustainable yields and long-term agricultural
              viability.
            </p>
          </div>
        </div>

        <div className="d-flex justify-content-center align-items-center  cropManagementWrapper ">
          <div className="cropManagPestControl  m-1 m-lg-2 d-flex flex-column align-items-center ">
            <div className="practiceContent d-flex  flex-column mt-2 ">
              <h2 className="cropManageHeadings">PEST CONTROL</h2>
              <img
                src={pestControl}
                alt="pest control"
                className="pestControl"
                loading="lazy"
              />
              <p className="cropMangPestControlContentBtn py-1">
                Protect Your Crops the Natural Way: Pest Control Strategies That
                Work. Harness Nature's Defenses to Safeguard Your Harvests
                Against Pests
              </p>
              <button
                className="cropMangeKnowMore py-1 cropMangPestControlContentBtn"
                onClick={() => detailsPage("Pest Control")}
              >
                Know More
              </button>
            </div>
          </div>

          <div className="cropManagePractices ">
            <div className="d-flex cropManagePractice">
              <div className="d-flex flex-column align-items-center justify-content-center ">
                <img
                  src={cropRotation}
                  alt="crom management"
                  loading="lazy"
                  className="cropManagPics"
                />
                <button
                  className="cropMangeKnowMore py-1"
                  onClick={() => detailsPage("Crop Rotation")}
                >
                  Know More
                </button>
              </div>
              <div className="d-flex flex-column px-2 px-sm-4 ">
                <div className="practiceContent d-flex  flex-column mt-2 ">
                  <h2 className="cropManageHeadings">CROP ROTATION</h2>
                  <p>
                    Optimize Your Harvests with Smart Crop Rotation Techniques.
                    Discover How Alternating Crops Boosts Soil Health and
                    Yields.
                  </p>
                </div>
              </div>
            </div>
            <div className="d-flex py-1 py-lg-4 cropManagePracticeLayer2">
              <div className="d-flex flex-column justify-content-center px-2 px-sm-4">
                <div className="practiceContent d-flex  flex-column mt-2 ">
                  <h2 className="cropManageHeadings">COMPANION PLANTING</h2>
                  <p>
                    Unlock Nature's Secret Partnerships with Companion Planting.
                    Learn How Strategic Plant Pairings Enhance Growth and Pest
                    Resistance.
                  </p>
                </div>
              </div>
              <div className="d-flex flex-column align-items-center justify-content-center">
                <img
                  src={companionPlanting}
                  alt="companion planting "
                  className="cropManagPics"
                  loading="lazy"
                />
                <button
                  className="cropMangeKnowMore py-1"
                  onClick={() => detailsPage("Companion Planting")}
                >
                  Know More
                </button>
              </div>
            </div>
            <div className="d-flex py-1 py-lg-4 cropManagePractice">
              <div className="d-flex flex-column align-items-center justify-content-center ">
                <img
                  src={weedManagement}
                  alt="weed management"
                  loading="lazy"
                  className="cropManagPics"
                />
                <button
                  className="cropMangeKnowMore py-1"
                  onClick={() => detailsPage("Weed Management")}
                >
                  Know More
                </button>
              </div>
              <div className="d-flex flex-column justify-content-center px-2 px-sm-4">
                <div className="practiceContent d-flex  flex-column mt-2 ">
                  <h2 className="cropManageHeadings">WEED MANAGEMENT</h2>
                  <p>
                    Tackle Weeds Naturally with Effective Management Strategies.
                    Explore Eco-Friendly Approaches to Suppress Weed Growth and
                    Preserve Soil Health. Discover How Alternating Crops Boosts
                    Soil Health and Yields.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CropManagement;
