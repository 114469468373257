import React, { useState, useRef, useContext } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import { FaArrowLeftLong } from "react-icons/fa6";
import "./applicationForm.css";
import { Form, Button, FloatingLabel, Row, Col } from "react-bootstrap";
import config from "../../Boot/config.json";
import axios from "axios";
import App, { AuthContext } from "../../App";
import HelmetWrapper from "../../HelmetWrapper";

function ApplicationForm() {
  const { setTokenData } = useContext(AuthContext);
  const navigation = useNavigate();
  const { jobId, title } = useParams();
  const resumeRef = useRef(null);
  const [resumeError, setResumeError] = useState("");
  const [showAdditionalFields, setShowAdditionalFields] = useState(false);
  const [submitText, setSubmitText] = useState("Submit");
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    YearOfGraduation: "",
    Gender: "",
    Experience: "",
    SkillSet: [""],
    Address: "",
    city: "",
    PresentLocation: "",
    state: "",
    EnglishProficiency: null,
    Relocation: null,
    LivingSituation: null,
    Religion: "",
    Community: "",
    CareerPlan: "",
    Resume: null,
  });

  const indianStates = [
    "Andhra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chhattisgarh",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Odisha",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Telangana",
    "Tripura",
    "Uttar Pradesh",
    "Uttarakhand",
    "West Bengal",
    "Andaman and Nicobar Islands",
    "Chandigarh",
    "Dadra and Nagar Haveli and Daman and Diu",
    "Delhi",
    "Lakshadweep",
    "Puducherry",
  ];
  function urlEncoded(title) {
    return title
      .toLowerCase() // Optional: convert to lowercase
      .replace(/[^a-z0-9]+/g, "-") // Replace non-alphanumeric characters with hyphens
      .replace(/^-+|-+$/g, ""); // Remove leading and trailing hyphens
  }

  const handleCheckboxChange = () => {
    setShowAdditionalFields(!showAdditionalFields);
  };

  const handleRadioChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const [emailError, setEmailError] = useState("");

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    if (name === "email") {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const isValidEmail = emailRegex.test(value);
      setEmailError(isValidEmail ? "" : "Invalid email format");
    }

    if (
      name === "phone" ||
      name === "YearOfGraduation" ||
      name === "Experience"
    ) {
      const onlyNumbers = value.replace(/\D/g, "");
      setFormData((prevData) => ({ ...prevData, [name]: onlyNumbers }));
    } else {
      setFormData((prevData) => ({ ...prevData, [name]: value }));
    }
  };

  const handleFileInputChange = (event) => {
    const { name, files } = event.target;
    if (event.target.files[0].type === "application/pdf") {
      setFormData((prevData) => ({ ...prevData, [name]: files[0] }));
      setResumeError("");
    } else {
      setResumeError("pls upload pdf only");
      return;
    }
  };
  function alertMsg(messages, alertStatus) {
    setTokenData((prevState) => ({
      ...prevState,
      alertMsg: messages,
      alertValue: true,
      alertStatus,
    }));
  }

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formDataToSend = new FormData();
    for (const [key, value] of Object.entries(formData)) {
      formDataToSend.append(key, value);
    }
    formDataToSend.append("title", title);
    formDataToSend.append("GCRInfo", showAdditionalFields);

    try {
      setSubmitText("Submitting...");
      const submitBtn = document.querySelector(".sumitBtn");
      submitBtn.disabled = true;

      await axios.post(`${config.baseUrl}/Jobs/jobApplication`, formDataToSend);
      // Reset form data and other UI elements here
      setFormData({
        name: "",
        phone: "",
        email: "",
        YearOfGraduation: "",
        Gender: "",
        Experience: "",
        SkillSet: [""],
        Address: "",
        city: "",
        PresentLocation: "",
        state: "",
        EnglishProficiency: null,
        Relocation: null,
        LivingSituation: null,
        Religion: "",
        Community: "",
        CareerPlan: "",
        Resume: null,
      });

      if (resumeRef.current) {
        resumeRef.current.value = null;
      }
      setShowAdditionalFields(false);
      // Reset form inputs
      document.querySelectorAll('input[type="radio"]').forEach((el) => {
        el.checked = false;
      });
      document.querySelectorAll("select").forEach((el) => {
        el.selectedIndex = 0;
      });
      submitBtn.disabled = false;

      setSubmitText("Submit");
      //navigate to job description
      navigation(`/careers/${urlEncoded(title)}/${jobId}`);
      alertMsg("Thank you for applying! We will get back to you soon..", true);
      setTimeout(() => {
        setTokenData((prevState) => ({ ...prevState, alertValue: false }));
      }, 1500);
    } catch (err) {
      console.error("Error submitting the form:", err);
    }
  };

  return (
    <>
      <HelmetWrapper
        title="Application for Professional Opportunity at Jaikisan"
        description="Discover job opportunities with Jaikisan"
        canonicalUrl={`https://www.jaikisan.co/applylink/${title}/${jobId}`}
      />
      <div>
        <div>
          <Link
            to={`/careers/${urlEncoded(title)}/${jobId}`}
            className="text-decoration-none"
          >
            <button className="careerbackb px-2 py-1 position-fixed text-center left-0 ">
              <FaArrowLeftLong />
            </button>
          </Link>
        </div>
        <div className="wr_application">
          <div className="applicationForm bg-white rounded-4 mx-auto">
            <h4 style={{ textDecoration: "underline" }} className="mb-4">
              Application Form
            </h4>
            <Form className="mainForm" onSubmit={handleSubmit}>
              <Form.Group className="mb-3">
                <FloatingLabel label="Full Name *">
                  <Form.Control
                    type="text"
                    placeholder="Enter your Name"
                    onChange={handleInputChange}
                    value={formData.name}
                    name="name"
                    required
                  />
                </FloatingLabel>
              </Form.Group>
              <Form.Group className="mb-3">
                <FloatingLabel label="Mobile Number *">
                  <Form.Control
                    type="tel"
                    minLength={10}
                    maxLength={10}
                    placeholder="0123456789"
                    onChange={handleInputChange}
                    value={formData.phone}
                    name="phone"
                    required
                  />
                </FloatingLabel>
              </Form.Group>
              <Form.Group className="mb-3">
                <FloatingLabel label="Email Address *">
                  <Form.Control
                    type="email"
                    placeholder="name@example.com"
                    onChange={handleInputChange}
                    value={formData.email}
                    name="email"
                    required
                  />
                  <p className="text-danger">{emailError}</p>
                </FloatingLabel>
              </Form.Group>
              <Form.Group className="mb-3">
                <FloatingLabel label="Year of Graduation *">
                  <Form.Control
                    type="tel"
                    minLength={4}
                    maxLength={4}
                    placeholder="Year"
                    onChange={handleInputChange}
                    value={formData.YearOfGraduation}
                    name="YearOfGraduation"
                    required
                  />
                </FloatingLabel>
              </Form.Group>
              <Form.Group className="mb-3">
                <FloatingLabel label="Years of Experience(if any)">
                  <Form.Control
                    type="tel"
                    maxLength={2}
                    placeholder="Years of experience"
                    onChange={handleInputChange}
                    name="Experience"
                    value={formData.Experience}
                  />
                </FloatingLabel>
              </Form.Group>
              <Form.Group className="mb-3">
                <FloatingLabel label="Skill Set *">
                  <Form.Control
                    type="textarea"
                    name="SkillSet"
                    placeholder="Enter your skills"
                    onChange={handleInputChange}
                    value={formData.SkillSet}
                    style={{ height: "100px" }}
                    required
                  />
                </FloatingLabel>
              </Form.Group>
              <FloatingLabel label="Address *">
                <Form.Control
                  type="textarea"
                  placeholder="Enter your address"
                  name="Address"
                  onChange={handleInputChange}
                  value={formData.Address}
                  className="mb-3"
                  style={{ height: "100px" }}
                  required
                />
              </FloatingLabel>
              <Form.Control
                type="textarea"
                placeholder="Enter Your City *"
                name="city"
                onChange={handleInputChange}
                value={formData.city}
                className="mb-3"
                required
              />
              <Form.Control
                type="textarea"
                placeholder="Current Location *"
                name="PresentLocation"
                onChange={handleInputChange}
                value={formData.PresentLocation}
                className="mb-3"
                required
              />
              <Form.Select
                aria-label="State"
                className="mb-3"
                name="state"
                onChange={handleInputChange}
                required
              >
                <option disabled>State *</option>
                {indianStates.map((state, index) => (
                  <option key={index}>{state}</option>
                ))}
              </Form.Select>
              <Form.Group as={Row} className="mb-3 ">
                <Form.Label as="legend" column sm={2} lg={4}>
                  Are you fluent in English?
                </Form.Label>
                <Col sm={5}>
                  <Form.Check
                    type="radio"
                    label="Yes"
                    id="1e"
                    value="Yes"
                    name="EnglishProficiency"
                    onChange={handleRadioChange}
                  />
                  <Form.Check
                    type="radio"
                    label="No"
                    value="No"
                    id="2e"
                    name="EnglishProficiency"
                    onChange={handleRadioChange}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3">
                <Form.Label as="legend" column sm={2} lg={5}>
                  Are you willing to move to Hyderabad?
                </Form.Label>
                <Col sm={5}>
                  <Form.Check
                    type="radio"
                    label="Yes"
                    id="1r"
                    name="Relocation"
                    value="Yes"
                    onChange={handleRadioChange}
                  />
                  <Form.Check
                    type="radio"
                    label="No"
                    id="2r"
                    name="Relocation"
                    value="No"
                    onChange={handleRadioChange}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3">
                <Form.Label as="legend" column sm={2} lg={5}>
                  Are you living with your Family or in hostel?
                </Form.Label>
                <Col sm={5}>
                  <Form.Check
                    type="radio"
                    label="Family"
                    id="1l"
                    name="LivingSituation"
                    value="Family"
                    onChange={handleRadioChange}
                  />
                  <Form.Check
                    type="radio"
                    id="2l"
                    label="Hostel"
                    name="LivingSituation"
                    value="Hostel"
                    onChange={handleRadioChange}
                  />
                </Col>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Career Plan</Form.Label>
                <Form.Control
                  as="textarea"
                  placeholder="Describe your career plan"
                  name="CareerPlan"
                  onChange={handleInputChange}
                  value={formData.CareerPlan}
                  className="careerPlanArea"
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Check
                  type="checkbox"
                  label="Additional Info (optional)"
                  onChange={handleCheckboxChange}
                  id="additionalInfoCheckbox"
                />
              </Form.Group>
              {showAdditionalFields && (
                <>
                  <Form.Group className="mb-3">
                    <FloatingLabel label="Gender" onChange={handleInputChange}>
                      <Form.Select aria-label="Gender" name="Gender">
                        <option>Choose...</option>
                        <option>Male</option>
                        <option>Female</option>
                        <option>Transgender</option>
                        <option>Prefer not to disclose</option>
                      </Form.Select>
                    </FloatingLabel>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <FloatingLabel label="Religion">
                      <Form.Control
                        type="text"
                        placeholder="Enter your religion"
                        name="Religion"
                        onChange={handleInputChange}
                        value={formData.Religion}
                      />
                    </FloatingLabel>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <FloatingLabel label="Community">
                      <Form.Control
                        type="text"
                        placeholder="Enter your community"
                        name="Community"
                        onChange={handleInputChange}
                        value={formData.Community}
                      />
                    </FloatingLabel>
                  </Form.Group>
                </>
              )}
              <Form.Group className="mb-3">
                <Form.Label>
                  Resume (PDF)<sup>*</sup>
                </Form.Label>
                <Form.Control
                  type="file"
                  name="Resume"
                  ref={resumeRef}
                  onChange={handleFileInputChange}
                  required
                />
                <p className="text-danger">{resumeError}</p>
              </Form.Group>
              <Button
                variant="primary"
                className="sumitBtn"
                type="submit"
                style={{
                  cursor:
                    submitText === "Submitting..." ? "not-allowed" : "auto",
                }}
              >
                {submitText}
              </Button>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
}

export default ApplicationForm;
