import React, { useEffect, useState, useContext, useRef } from "react";
import { useParams, Link, useLocation, useNavigate } from "react-router-dom";
import config from "../../Boot/config.json";
import profileImg from "../../Assets/profileImg.jpg";
import editProfile from "../../Assets/edit_pencil.png";
// import leftArrow from "../../Assets/left-arrow.png";
import { FaArrowLeftLong } from "react-icons/fa6";
import { MdEdit } from "react-icons/md";
import { AuthContext } from "../../App";
import axios from "axios";
import "./userProfile.css";
import HelmetWrapper from "../../HelmetWrapper";

function UserProfile() {
  const { userId } = useParams();
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(true);
  const [blogsList, setBlogsList] = useState([]);
  const [agency, setAgency] = useState({});
  const [error, setError] = useState(null);
  const [userProDetails, setUserProDetails] = useState({
    name: "",
    email: "",
    phone: "",
    image: null,
    password: "",
    confirmPassword: "",
    editProfileStatus: false,
    profileUpdated: false,
    formSubmitErros: "",
    previewImage: null,
  });

  const { tokenData, setTokenData } = useContext(AuthContext);
  const fileInputRef = useRef(null);

  const axiosInstance = axios.create({
    baseURL: `${config.baseUrl}/Users`,
    headers: {
      Authorization: tokenData.token,
      "Content-Type": "multipart/form-data",
    },
  });
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    axiosInstance
      .get(`/User/${userId}`)
      .then((response) => {
        setUser(response.data.data);
        setBlogsList(response.data.blogs);
        setUserProDetails((prevState) => ({
          ...prevState,
          name: response.data.data.name,
          email: response.data.data.email,
          phone: response.data.data.phone,
        }));
        setAgency(response.data.agency);
        setLoading(false);
      })
      .catch((error) => {
        console.error("There was an error fetching the user data!", error);
        setError("Error fetching user data");
        setLoading(false);
      });
  }, [userId, userProDetails.profileUpdated]);

  if (loading) return <div className="loading">Loading...</div>;
  if (error) return <div className="error">{error}</div>;

  function alertMsg(messages, alertStatus) {
    setTokenData((prevState) => ({
      ...prevState,
      alertMsg: messages,
      alertValue: true,
      alertStatus,
    }));
  }

  const handleBackClick = () => {
    if (location.state && location.state.from) {
      navigate(`/${location.state.from}`);
    } else {
      navigate(-1);
      navigate(-1); // This will go back to the last page in history
    }
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();
    try {
      const { password, confirmPassword, name, email, phone, image } =
        userProDetails;
      console.log("hiiii");

      // Check if passwords are provided and match
      if (
        password.trim().length > 0 &&
        password.trim() !== confirmPassword.trim()
      ) {
        setUserProDetails((prev) => ({
          ...prev,
          formSubmitErros: "password missmatch",
        }));

        setTimeout(() => {
          setUserProDetails((prev) => ({
            ...prev,
            formSubmitErros: "",
          }));
        }, 2000);
        return;
      }

      // Construct formData
      const formData = { name, email, phone };
      if (password.trim().length > 0) {
        formData.password = password;
        formData.confirmPassword = confirmPassword;
      }
      if (image != null) {
        formData.image = image;
      }
      console.log("hiiii");
      // Submit the form data
      await axiosInstance.patch(`/EditProfile/${userId}`, formData);
      console.log("profileStatus=====", tokenData.profileStatus);
      console.log(tokenData);

      setTokenData((prevData) => ({
        ...prevData,
        profileStatus: !tokenData.profileStatus,
      }));
      console.log("profileStatus", tokenData.profileStatus);

      alertMsg("Profile Updated Successfully", true);
      setUserProDetails((prevState) => ({
        ...prevState,
        profileUpdated: !prevState.profileUpdated,
        editProfileStatus: false,
      }));

      // Reset alert after 2 seconds
      setTimeout(() => {
        setTokenData((prevState) => ({
          ...prevState,
          alertValue: false,
        }));
      }, 2000);
    } catch (err) {
      console.log(err);
    }
  };

  const handleEditProfile = (status) => {
    console.log("clicked");
    if (status === "active") {
      setUserProDetails((prevState) => ({
        ...prevState,
        editProfileStatus: true,
      }));
    } else if (status === "inactive") {
      setUserProDetails((prevState) => ({
        ...prevState,
        editProfileStatus: false,
        previewImage: null,
      }));
    }
  };

  function urlEncoded(title) {
    return title
      .toLowerCase() // Optional: convert to lowercase
      .replace(/[^a-z0-9]+/g, "-") // Replace non-alphanumeric characters with hyphens
      .replace(/^-+|-+$/g, ""); // Remove leading and trailing hyphens
  }

  const handleEditProfileChange = (e) => {
    const { value, id, files } = e.target;
    if (id === "phone") {
      const numericOnly = value.replace(/\D/g, "");
      const truncatedValue = numericOnly.slice(0, 10);
      setUserProDetails((prevFD) => ({ ...prevFD, [id]: truncatedValue }));
    } else if (id === "image") {
      const selectedFile = files[0];
      if (selectedFile) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setUserProDetails((prevData) => ({
            ...prevData,
            previewImage: reader.result,
          }));
          setUserProDetails((prevFD) => ({ ...prevFD, [id]: selectedFile }));
        };
        reader.readAsDataURL(selectedFile);
      }
    } else {
      setUserProDetails((prevFD) => ({ ...prevFD, [id]: value }));
    }
  };

  return (
    <div className="user-profile-container">
      <HelmetWrapper
        title={`${user.name} - Empowering Sustainable Agriculture | Jaikisan `}
        description={`${user.name}'s profile on Jaikisan. Represent leading companies, trade with Jaikisan, manage and create blogs, and contribute to sustainable agriculture. Partner with us to support local farmers and promote eco-friendly farming practices.`}
        canonicalUrl={`https://www.jaikisan.co/protected/UserProfile/${userId}`}
      />
      <button
        className="position-fixed start-0 m-3 backBtnProfile rounded-1 shadow-lg border-0 text-white"
        onClick={() => handleBackClick()}
      >
        {/* <img src={leftArrow} alt="" className="backLeftArrow" /> */}
        <FaArrowLeftLong />
      </button>
      <div className="user-profile-card position-relative">
        <div className="user-profile-header position-relative">
          {tokenData.token && !userProDetails.editProfileStatus && (
            <button
              className="position-absolute top-0 end-0  rounded-square editBtnWrapper"
              onClick={() => handleEditProfile("active")}
            >
              <img
                src={editProfile}
                alt="edit user profile"
                className="editProfileImg"
                loading="lazy"
              />
            </button>
          )}
          <div className="position-relative jusity-content-center align-items-center">
            <img
              src={
                userProDetails.previewImage ||
                (user.profile === ""
                  ? profileImg
                  : `${config.deployUrl}${user.profile}`)
              }
              alt={`${user.name}'s profile`}
              className="user-profile-picture"
              loading="lazy"
              onClick={
                userProDetails.editProfileStatus
                  ? () => fileInputRef.current.click()
                  : undefined
              }
            />
            {userProDetails.editProfileStatus ? (
              <div
                className="rounded-circle editTransLayer  position-absolute top-0 start-50 d-flex justify-content-center align-items-center"
                onClick={() => fileInputRef.current.click()}
              >
                <MdEdit />
              </div>
            ) : (
              <></>
            )}
          </div>
          <h1 className="user-name">{user.name}</h1>
        </div>
        {userProDetails.editProfileStatus && (
          <div className="user-profile-edit px-3 py-0">
            <div>
              <div>
                <label htmlFor="Name">Name</label>
              </div>
              <input
                type="text"
                placeholder="Enter Name"
                id="name"
                onChange={(e) => handleEditProfileChange(e)}
                value={userProDetails.name}
              />
            </div>
            <div className=" py-sm-1">
              {/* <div>
                <label htmlFor="image">Profile Picture</label>
              </div> */}
              {/* <div className="custom-file-upload"> */}
              {/* <button
                  className="custom-file-button"
                  onClick={() => fileInputRef.current.click()}
                >
                  Upload Profile
                </button> */}
              <input
                type="file"
                id="image"
                accept="image/jpeg, image/png"
                onChange={handleEditProfileChange}
                ref={fileInputRef}
                style={{ display: "none" }}
              />
              {/* </div> */}
            </div>
            <div className="d-flex justify-content-between py-2 flex-sm-row flex-column">
              <div>
                <div>
                  <label htmlFor="Email">Email</label>
                </div>
                <input
                  type="text"
                  placeholder="Enter Email"
                  id="email"
                  onChange={(e) => handleEditProfileChange(e)}
                  value={userProDetails.email}
                />
              </div>
              <div>
                <div>
                  <label htmlFor="Email">Phone</label>
                </div>
                <input
                  type="number"
                  placeholder="Enter Phone"
                  id="phone"
                  onChange={(e) => handleEditProfileChange(e)}
                  value={userProDetails.phone}
                />
              </div>
            </div>
            <div className="d-flex justify-content-between flex-column  py-sm-1">
              <div className="d-flex flex-sm-row flex-column justify-content-between">
                <div>
                  <div>
                    <label htmlFor="password">Password</label>
                  </div>
                  <input
                    type="password"
                    placeholder="Enter Password"
                    id="password"
                    onChange={(e) => handleEditProfileChange(e)}
                  />
                </div>
                <div>
                  <div>
                    <label htmlFor="confirm password">Confirm Password</label>
                  </div>
                  <input
                    type="text"
                    placeholder=" Confirm Password"
                    id="confirmPassword"
                    onChange={(e) => handleEditProfileChange(e)}
                  />
                </div>
              </div>
              {userProDetails.formSubmitErros === "password missmatch" ? (
                <p className="text-danger">{userProDetails.formSubmitErros}</p>
              ) : (
                <p></p>
              )}
            </div>
            <div className="my-2">
              <button
                className="border-0 px-2 py-1 shadow-sm me-3 bg-success text-white rounded-1"
                onClick={(e) => handleEditSubmit(e)}
              >
                Save
              </button>
              <button
                className="border-0  px-2 py-1 shadow-sm me-3 bg-danger  text-white p-1 rounded-1"
                onClick={() => handleEditProfile("inactive")}
              >
                Close
              </button>
            </div>
          </div>
        )}
        <div className="user-profile-details">
          {/* <p>
            <strong>User ID:</strong> {user._id}
          </p> */}
          <p>
            <strong>Email:</strong> {user.email}
          </p>
          <p>
            <strong>Phone:</strong> {user.phone}
          </p>
          {agency.agencyName !== "GlobalAgency" && (
            <p className="d-flex justify-content-between align-items-center">
              <div>
                <strong>Agency:</strong> {agency.agencyName}
              </div>

              <Link
                to={`/agency/${urlEncoded(
                  agency.agencyName
                )}/${agency.jkAgencyId.slice(4, 10)}`}
              >
                <button className="border-0 p-2 bg-secondary text-white rounded-2">
                  Visit Agency
                </button>
              </Link>
            </p>
          )}
        </div>
        <div className="user-blogs">
          <h2>Blogs</h2>
          <ul className="blogs-list">
            {blogsList.map((blog, index) => (
              <li key={blog.jkBlogId} className="blog-item">
                <Link
                  to={`/blog/${urlEncoded(blog.blogName)}/${blog.jkBlogId.slice(
                    4,
                    10
                  )}`}
                  className="blog-link"
                >
                  {index + 1}) {blog.blogName}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default UserProfile;
