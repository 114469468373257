import { useLocation } from "react-router-dom";
import FarmingHome from "../../FarmingHome/FarmingHome";
import AboutWebsite from "../../AboutWebsite/AboutWebsite";
import FarmingPractices from "../../FarmingPractices/FarmingPractices";
import SoilHealth from "../../SoilHealth/SoilHealth";
import CropManagement from "../../CropManagement/CropManagement";
import Footer from "../../Footer/Footer";
import HelmetWrapper from "../../../HelmetWrapper";

function Home() {
  const location = useLocation();
  console.log(location.pathname);
  return (
    <section>
      <HelmetWrapper
        title="Jaikisan | Home"
        description="Join Jaikisan to discover farming best practices, organic products, and farmer empowerment. Support sustainable agriculture and buy directly from farmers."
        canonicalUrl="https://www.jaikisan.co"
      />

      <div id="Home">
        <FarmingHome />
      </div>
      <div id="AboutUs">
        <AboutWebsite />
      </div>
      <div>
        <FarmingPractices />
      </div>
      <div>
        <SoilHealth />
      </div>
      <div>
        <CropManagement />
      </div>
      <div id="Contact">
        <Footer />
      </div>
    </section>
  );
}

export default Home;
