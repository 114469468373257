import React from "react";
import "./draftsComponent.css";
import FarmersBlogs from "../FarmersBlogs/FarmersBlogs";
function DraftsComponent() {
  return (
    <div style={{ minHeight: "1000px" }}>
      <FarmersBlogs draftsMode={true} />
    </div>
  );
}

export default DraftsComponent;
