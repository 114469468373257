import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { AuthContext } from "../../App";
import jaikisanLogo from "../../Assets/jaikisanLogo.jpg";
import config from "../../Boot/config.json";
import "./login.css";

function Login() {
  const navigate = useNavigate();
  const { setTokenData } = useContext(AuthContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  function alertMsg(messages, alertStatus) {
    setTokenData((prevState) => ({
      ...prevState,
      alertMsg: messages,
      alertValue: true,
      alertStatus,
    }));
  }

  const handleChange = (e) => {
    const { id, value } = e.target;
    if (id === "email") {
      setEmail(value);
    }
    if (id === "password") {
      setPassword(value);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = {
      email: email,
      password: password,
    };
    try {
      if (!email) {
        return setError("Enter Email");
      }
      if (!password) {
        return setError("Enter Password");
      }

      axios
        .post(`${config.baseUrl}/Users/Login`, formData)
        .then((response) => {
          alertMsg("Login Successfull", true);
          setTimeout(() => {
            setTokenData((prevState) => ({ ...prevState, alertValue: false }));
          }, 1500);
          const authData = {
            token: response.data.token,
            roleId: response.data.roleId,
            userId: response.data.userId,
            agencyId: response.data.agencyId,
          };

          console.log(response.data);
          sessionStorage.setItem("JaikisanJWT", JSON.stringify(authData));
          setTokenData((prevState) => ({
            ...prevState,
            token: response.data.token,
            roleId: response.data.roleId,
            userId: response.data.userId,
            agencyId: response.data.agencyId,
          }));
          if (
            response.data.roleId === "Admin" ||
            response.data.roleId === "User" ||
            response.data.roleId === "Guest"
          )
            navigate("/protected/myBlogs");
        })
        .catch((err) => {
          if (err.response.data === "Not a valid password") {
            setError(err.response.data);
          } else if (err.response.data === "No user found") {
            setError("Please register");
          } else if (err.response.data === "No password") {
            setError("Please enter password");
          }
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="loginWrapper d-flex justify-content-center align-items-center">
      <div className="loginContentWrapper d-flex align-items-center position-relative">
        <div className="position-absolute jaikisanLogo">
          <img
            src={jaikisanLogo}
            className="jaikisanLogoImg"
            alt="Jaikisan Logo"
            loading="lazy"
          />
        </div>
        <form
          className="loginContentForm  d-flex flex-column justify-content-center "
          onSubmit={handleSubmit}
        >
          <div className="my-3">
            <div>
              <label htmlFor="email" className="labelHeadings">
                Email
              </label>
            </div>
            <input
              type="text"
              placeholder="Enter Email"
              className="py-2 form-control"
              id="email"
              onChange={handleChange}
            />
            {error === "Enter Email" && (
              <p className="text-danger p-0 m-0">{error}</p>
            )}
          </div>
          <div className="my-3">
            <div>
              <label htmlFor="password" className="labelHeadings">
                Password
              </label>
            </div>
            <input
              type="password"
              placeholder="Enter Password"
              className="py-2 form-control"
              id="password"
              onChange={handleChange}
            />
          </div>
          {(error === "Not a valid password" ||
            error === "Please register" ||
            error === "Please enter password" ||
            error === "Enter Password") && (
            <p className="text-danger p-0 m-0">{error}</p>
          )}
          <div className="my-1 ">
            <button
              type="submit"
              className="loginBtn btn btn-primary px-3 py-2"
            >
              Login
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Login;
